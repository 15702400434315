import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Actions",
  "slug": "actions"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Actions`}</h1>
    <p>{`An action endpoint in enableHR OpenAPI is used to submit long running changes to enableHR. A long running change is typically an activity that will change a number of tables and takes more time that a typical CRUD operation.`}</p>
    <p>{`An action endpoint will return a Location Header with an action status URL. Clients are expected to call this action status URL iteratively till the returned action status is either Failed or Completed.`}</p>
    <p>{`All action endpoints contain the word actions.`}</p>
    <p>{`A successful call will return HTTP 202 with an action status URL`}</p>
    <p><inlineCode parentName="p">{`{ "actionStatusURL": "http://api.enablehr.com/accounts/{acctId}/action-status/{actionStatusId}" }`}</inlineCode></p>
    <h2>{`List of actions endpoints`}</h2>
    <p>{`All these endpoint return an action status URL.`}</p>
    <h6>{`/accounts/{accountId}/candidates/{candidateId}/actions/convert`}</h6>
    <p>{`This action is used to convert a candidate into an employee`}</p>
    <h6>{`/accounts/{accountId}/candidates/{candidateId}/actions/merge`}</h6>
    <p>{`This action is to merge two candidates`}</p>
    <h6>{`/accounts/{accountId}/employees/{employeeId}/actions/terminate`}</h6>
    <p>{`This action is user to terminate an employee`}</p>
    <h6>{`/accounts/{accountId}/employees/{employeeId}/actions/transfer`}</h6>
    <p>{`This action is used to transfer an employee between branches`}</p>
    <h6>{`/accounts/{accountId}/action-status/{actionStatusId}`}</h6>
    <p>{`This call allows the client to determine the status of a previous action. The accountStatusId is the id that was returned when the long running action was called. It is returned in the Location: header on a successful submit of an action`}</p>
    <h2>{`Action Status Fields`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The identifier for the ActionStatusDetail`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actionStatus`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Enum: Pending, In_Progress, Completed, Failed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`referenceId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The id of the item this action is being performed on e.g. the employee id or the candidate id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`referenceType`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Enum: Employee, Candidate, User. Given the type of the reference id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Enum: Terminate_Employee, Transfer_Employee_Move_Everything, Transfer_Employee_Copy, Transfer_Employee_Move_Everything_No_Trace, Convert_Candidate_To_Employee, Merge_Candidate -type of the original action`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`dateCreatedUTC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`UTC time of when this action was created (format is YYYY-MM-DD HH:MM:SS)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`dateUpdatedUTC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`UTC time of when this action was updated. (format is YYYY-MM-DD HH:MM:SS)`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      